<template>
  <div>
    <table class="print-insalubrity table-bordered">
      <thead>
        <tr>
          <th rowspan="2" class="col-first emph">CAS: Agente</th>
          <th colspan="6" class="col-first emph">ACGIH 2024</th>
        </tr>
        <tr>
          <th class="col-first emph">Fração de Coleta</th>
          <th class="col-first emph">TWA</th>
          <th class="col-first emph">STEL</th>
          <th class="col-first emph">Teto</th>
          <th class="col-first emph">Notações</th>
          <th class="col-first emph">Base de TLV</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in summary">
          <template v-if="model.getInteractions(item) === 'all-interactions'">
            <tr :key="item.searchId">
              <td :rowspan="item.acgihInfo.limits.length + 1">
                {{ model.getValue('searchId', item) }}
              </td>
            </tr>
            <tr v-for="row in item.acgihInfo.limits.length" :key="`row_${row}`">
              <template v-for="(field, index) in model.tableFields">
                <td
                  v-if="field.key !== 'searchId'"
                  :key="`field_${field.key}`"
                  :class="`field_${field.key}`"
                  :col="index"
                >
                  <template
                    v-if="
                      field.key === 'base_do_tlv' || field.key === 'notations'
                    "
                  >
                    <div
                      v-html="model.getValue(field.key, item, row - 1)"
                    ></div>
                  </template>
                  <template v-else>
                    {{ model.getValue(field.key, item, row - 1) }}
                  </template>
                </td>
              </template>
            </tr>
          </template>
          <tr
            v-else-if="
              model.getInteractions(item) === 'one-interaction' ||
              model.getInteractions(item) === 'none-interaction'
            "
            :key="item.legalSearchId"
          >
            <td
              v-for="field in model.tableFields"
              :key="`field_${field.key}`"
              :class="`field_${field.key}`"
            >
              <template
                v-if="field.key === 'base_do_tlv' || field.key === 'notations'"
              >
                <div v-html="model.getValue(field.key, item)"></div>
              </template>
              <template v-else>
                {{ model.getValue(field.key, item) }}
              </template>
            </td>
          </tr>
          <tr
            v-else-if="model.getInteractions(item) === 'select-interaction'"
            :key="item.legalSearchId"
          >
            <td>{{ model.getValue('searchId', item) }}</td>
            <td colspan="11" class="selectable-row">
              <a href="#" @click="displayInteractions(item)">
                Agente necessita de determinação de atividade, selecione aqui
              </a>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import Acgih from '@/models/Acgih'
export default {
  data() {
    return {
      model: Acgih,
      selectedAgent: {},
    }
  },
  computed: {
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    user() {
      return this.$store.getters['user/current']
    },
  },
  async mounted() {
    await this.$store.dispatch('userData/updatePrintedReports', this.user.id)
    window.print()
  },
}
</script>

<style scoped>
@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
}
.title,
.table-print {
  width: 100vw;
}

.title tr th,
.table-print tr th {
  width: 15vw;
}

.title tr td,
.table-print tr td {
  width: 85vw;
}
.table-tolerance {
  width: 100%;
}
.table-tolerance tr th {
  padding: 5px 10px;
}
.emph {
  background: var(--sapphire-blue);
  color: white;
  font-weight: bold;
}
th,
td {
  padding: 10px;
}
.title tr td {
  background: var(--sapphire-blue);
  color: white;
  font-weight: bold;
  padding-top: 30px;
}

.title tr th:first-child {
  background-color: white;
  font-weight: bold;
  height: 100px;
}

.title tr th:first-child img {
  height: 90px;
}

.table-print tr th {
  background-color: var(--sapphire-blue);
  color: var(--white);
}
/* .print-insalubrity tr th:first, .print-insalubrity tr td:first{ */
.col-first {
  /* background-color: red; */
  width: 15%;
}
</style>
